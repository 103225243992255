import { Grid, Skeleton, Stack, useTheme } from "@mui/material"
import { Polygon } from "@react-google-maps/api"
import { t } from "i18next"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { CustomMap, IMarker } from "../../../partials/maps/custom-map"
import { MapsHelper } from "../../../partials/maps/maps-helper"
import { useCollectionGroupWizardContext } from "../context/collection-group-wizard-context"
import { CollectionGroupCollectionPointInfo } from "./collection-group-collection-point-info"
import { CollectionGroupCollectionPointsFilter } from "./collection-group-collection-points-filter"
import { CollectionGroupCollectionPointsTable } from "./collection-group-collection-points-table"

export enum MapModeOption {
  Hand = "hand",
  Polygon = "polygon",
}

export const CollectionGroupCollectionPointsForm: React.FC = () => {
  const {
    collectionPointsForMap,
    formValues,
    loading,
    collectionPointsForMap: allCollectionPoints,
    setFormValues,
  } = useCollectionGroupWizardContext()

  const theme = useTheme()
  const getMapHeight = useCallback(() => {
    const minHeight = 260
    const indicatorHeight = 100
    const headerHeight = 72
    const buttonWizardRow = 68
    const paddingsOffset = 36
    const height = window.innerHeight - indicatorHeight - headerHeight - buttonWizardRow - paddingsOffset
    return height > minHeight ? height : minHeight
  }, [])

  const [mapHeight, setMapHeight] = useState(getMapHeight())
  const [polygons, setPolygons] = useState<google.maps.Polygon[]>([])
  const [paths, setPaths] = useState<{ lat: number; lng: number }[][]>([[]])
  const [mode, setMode] = useState<MapModeOption.Hand | MapModeOption.Polygon>(MapModeOption.Hand)
  const [checkedMarkerIds, setCheckedMarkerIds] = useState<number[]>([])
  const mapRef = useRef<google.maps.Map | null>(null)
  const buttonRefs = useRef<{
    hand?: HTMLButtonElement
    polygon?: HTMLButtonElement
    clearPolygons?: HTMLButtonElement
  }>({})

  const createButtons = (map: google.maps.Map | null) => {
    if (map) {
      map.controls[google.maps.ControlPosition.LEFT_TOP].clear()

      const controlDiv = document.createElement("div")
      controlDiv.style.display = "flex"
      controlDiv.style.flexDirection = "row"
      controlDiv.style.backgroundColor = "#fff"
      controlDiv.style.border = "2px solid #fff"
      controlDiv.style.borderRadius = "4px"
      controlDiv.style.boxShadow = "0 2px 6px rgba(0,0,0,0.3)"
      controlDiv.style.marginTop = "30px"
      controlDiv.style.marginLeft = "8px"
      controlDiv.style.padding = "5px"

      const createButton = (label: string, isActive: boolean, onClick: () => void) => {
        const button = document.createElement("button")
        button.innerHTML = label
        button.style.fontSize = "18px"
        button.style.filter = "grayscale(100%)"
        button.style.border = "none"
        button.style.background = isActive ? "#ccc" : "transparent"
        button.style.cursor = "pointer"
        button.style.margin = "0 5px"
        button.style.padding = "8px"
        button.style.borderRadius = "3px"
        button.onclick = onClick
        return button
      }

      const createClearButton = (label: string, onClick: () => void) => {
        const button = document.createElement("button")
        button.innerHTML = label
        button.style.fontSize = "18px"
        button.style.border = "none"
        button.style.background = "transparent"
        button.style.cursor = "pointer"
        button.style.margin = "0 5px"
        button.style.padding = "8px"
        button.style.borderRadius = "3px"
        button.style.display = paths[0].length ? "block" : "none"
        button.style.color = "#666"
        button.onmouseover = () => {
          button.style.color = "#000"
        }
        button.onmouseout = () => {
          button.style.color = "#666"
        }
        button.onclick = onClick
        return button
      }

      buttonRefs.current.hand = createButton("✋🏿", mode === MapModeOption.Hand, () => setMode(MapModeOption.Hand))
      buttonRefs.current.polygon = createButton("⬟", mode === MapModeOption.Polygon, () =>
        setMode(MapModeOption.Polygon),
      )
      buttonRefs.current.clearPolygons = createClearButton(t("collection_points.clear"), clearPolygons)

      controlDiv.appendChild(buttonRefs.current.hand)
      controlDiv.appendChild(buttonRefs.current.polygon)
      controlDiv.appendChild(buttonRefs.current.clearPolygons)
      map.controls[google.maps.ControlPosition.LEFT_TOP].push(controlDiv)
    }
  }

  useEffect(() => {
    if (buttonRefs.current.hand && buttonRefs.current.polygon) {
      buttonRefs.current.hand.style.background = mode === MapModeOption.Hand ? "#ccc" : "transparent"
      buttonRefs.current.polygon.style.background = mode === MapModeOption.Polygon ? "#ccc" : "transparent"
      mapRef.current?.setOptions({ draggableCursor: mode === MapModeOption.Hand ? "grab" : "crosshair" })
    }
  }, [mode])

  useEffect(() => {
    if (loading) return
    const materialIds = formValues?.materialIds ?? []
    // when a material has been selected / deselected, we need to filter the collection point ids to only contain collection points that include the selected materials
    const currentlySelectedCollectionPointIds = formValues?.collectionPointIds ?? []
    const collectionPoints = allCollectionPoints.filter((cp) =>
      currentlySelectedCollectionPointIds.includes(Number(cp.id)),
    )

    const collectionPointsForMaterials = collectionPoints.filter((cp) => {
      const collectionPointMaterialIds = cp.containers.flatMap((c) => c.material_id)
      return materialIds.some((id) => collectionPointMaterialIds.includes(id))
    })

    setFormValues({
      ...formValues,
      collectionPointIds: collectionPointsForMaterials.map((cp) => Number(cp.id)),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCollectionPoints, formValues?.materialIds, setFormValues, loading])

  useEffect(() => {
    const handleResize = () => {
      setMapHeight(getMapHeight())
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [getMapHeight])

  const markers = useMemo(() => {
    const markers = MapsHelper.getMarkersWithFilllevels(collectionPointsForMap, 100)
    const mapped = markers.map((marker) => {
      const isSelected = formValues?.collectionPointIds?.includes(marker.id)
      const color = isSelected ? theme.palette.primary.main : theme.palette.secondary.light

      return {
        ...marker,
        filllevel: undefined,
        colorOverride: color.replace("#", ""),
      }
    })
    return mapped
  }, [collectionPointsForMap, formValues, theme])

  const renderCustomInfoWindow = (id: number) => {
    return <CollectionGroupCollectionPointInfo id={id} />
  }

  const fitMapBounds = (map: google.maps.Map) => {
    mapRef.current = map
    createButtons(map)
    if (markers.length > 1) {
      const bounds = new google.maps.LatLngBounds()

      markers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng))
      })

      map.fitBounds(bounds)
    } else if (markers.length === 1) {
      map.setCenter(new google.maps.LatLng(markers[0].lat, markers[0].lng))
      map.setZoom(15)
    }
  }

  const handleMapClick = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (mode !== MapModeOption.Polygon || !event.latLng) return
      if (event.latLng) {
        if (paths.length && paths[paths.length - 1].length < 3) {
          setPaths((prev) => {
            const newPath = [...prev]
            newPath[newPath.length - 1] = [
              ...newPath[newPath.length - 1],
              { lat: event.latLng!.lat(), lng: event.latLng!.lng() },
            ]
            return newPath
          })
        } else {
          setPaths((prev) => [...prev, [{ lat: event.latLng!.lat(), lng: event.latLng!.lng() }]])
        }
      }
    },
    [paths, mode],
  )

  const isMarkerInPolygon = useCallback(
    (marker: IMarker) => {
      return polygons.some((polygon) => {
        const googleMarker = new window.google.maps.LatLng(marker.lat, marker.lng)
        return window.google.maps.geometry.poly.containsLocation(googleMarker, polygon)
      })
    },
    [polygons],
  )

  const areEqual = (arr1: number[], arr2: number[]) =>
    arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index])

  const checkMarkersInsidePolygons = useCallback(() => {
    return markers.filter((marker) => isMarkerInPolygon(marker)).map((marker) => marker.id)
  }, [markers, isMarkerInPolygon])

  const updateCheckedMarkerts = useCallback(
    (markersIds: number[]) => {
      const filteredFormValuesCollectionPointIds = formValues?.collectionPointIds?.filter(
        (id) => !checkedMarkerIds.includes(id) || markersIds.includes(id),
      )
      const newFormValuesCollectionPointIds = markersIds.filter(
        (id) => !filteredFormValuesCollectionPointIds?.includes(id),
      )
      setFormValues({
        ...formValues,
        collectionPointIds: [...(filteredFormValuesCollectionPointIds ?? []), ...newFormValuesCollectionPointIds],
      })
      setCheckedMarkerIds(markersIds)
    },
    [formValues, setFormValues, checkedMarkerIds, setCheckedMarkerIds],
  )

  useEffect(() => {
    const markersIds: number[] = checkMarkersInsidePolygons()
    if (!areEqual(checkedMarkerIds, markersIds)) {
      updateCheckedMarkerts(markersIds)
    }

    if (buttonRefs.current?.clearPolygons?.style) {
      buttonRefs.current.clearPolygons.style.display = paths[0].length ? "block" : "none"
    }
  }, [paths, checkedMarkerIds, checkMarkersInsidePolygons, updateCheckedMarkerts])

  const onEdit = (index: number) => {
    if (polygons[index]) {
      const nextPath = polygons[index]
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() }
        })
      setPaths((prevPath) => {
        const newPath = [...prevPath]
        newPath[index] = nextPath
        return newPath
      })
    }
  }

  const onLoad = useCallback((polygon: google.maps.Polygon) => {
    setPolygons((prevPolygons) => [...prevPolygons, polygon])
  }, [])

  const clearPolygons = () => {
    setPolygons([])
    setPaths([[]])
  }

  const renderPolygons = () => {
    return (
      <>
        {paths?.map((path, idx) => (
          <Polygon
            editable
            draggable
            path={path}
            key={idx}
            onLoad={onLoad}
            onMouseUp={() => onEdit(idx)}
            onDragEnd={() => onEdit(idx)}
          />
        ))}
      </>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid id="table-grid-item" item xs={8}>
        <Stack direction="column" spacing={2}>
          <CollectionGroupCollectionPointsFilter />
          <CollectionGroupCollectionPointsTable />
        </Stack>
      </Grid>
      <Grid id="map-grid-item" item xs={4}>
        {!loading && (
          <CustomMap
            height={`${mapHeight}px`}
            markers={markers}
            doRenderCustomInfoWindow
            renderCustomInfoWindow={renderCustomInfoWindow}
            disableFitBounds
            disableMultipleInfoWindows
            onInit={fitMapBounds}
            onMapClicked={handleMapClick}
          >
            {paths?.[0].length && renderPolygons()}
          </CustomMap>
        )}
        {loading && <Skeleton variant="rectangular" height={600} />}
      </Grid>
    </Grid>
  )
}
