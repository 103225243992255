import { LocationType } from "../components/pages/user-management/helper/get-options-for-role"
import {
  UserRole,
  EmptyingType,
  STOP_TYPE,
  ExportReason,
  ContainerWarningStatus,
  ContainerWarningType,
  CollectionType,
  HouseholdContainerCommentType,
} from "../api/graphql/graphql-global-types"
import { CollectionPointStatus } from "../components/pages/tour-generation/partials/tour-export-preview-table"

export const de = {
  ok: "Ok",
  api_errors: {
    number_already_exists: "Es existiert bereits ein Behälter mit der selben Nummer.",
    not_found: "{{entity}} existiert nicht",
    create: "{{entity}} konnte nicht erstellt werden",
    update: "{{entity}} konnte nicht aktualisiert werden",
    delete: "{{entity}} konnte nicht gelöscht werden",
  },
  forbidden: "Für diese Aktion haben Sie keine Berechtigung.",
  warnmessage: "Warnmeldung",
  login: "Login",
  email_address: "E-Mail-Adresse",
  sign_in: "Anmelden",
  password: "Passwort",
  legal_notice: "Impressum",
  privacy_policy: "Datenschutz",
  add: "Hinzufügen",
  remove: "Entfernen",
  collection_point: "Sammelinsel",
  yesterday: "Gestern",
  today: "Heute",
  tomorrow: "Morgen",
  container: "Behälter",
  fill_level: "Füllstand",
  type: "Typ",
  fraction: "Fraktion",
  sensor_id: "Sensor ID",
  not_available: "N/A",
  days: "Tage",
  weeks: "Wochen",
  time: "Zeit",
  tour_time: "Tourdauer",
  vehicle: "Fahrzeug",
  trailer_location: "Anhängerstandort",
  image: "Bild",
  images: "Bilder",
  no_images: "Keine Bilder",
  partner: "Partner",
  yes: "Ja",
  no: "Nein",
  cancel: "Abbrechen",
  continue: "Weiter",
  save: "Speichern",
  search: "Suche",
  collected_amount: "Abgeholte Menge",
  amount_chambers: "Anzahl Kammern",
  association: "Sammelregion",
  district: "Sammelgebiet",
  assign: "Zuweisen",
  generic_error: "Ups! Da ist etwas schiefgelaufen",
  invalid_format: "Ungültiges Format",
  general_api_error: "Leider ist etwas schiefgelaufen. Bitte laden Sie die Seite erneut.",
  total: "Gesamt",
  portal: {
    update_available: "Version <strong>{{version}}</strong> ist verfügbar!",
    update: "Update",
    sidebar: {
      no_version: "DEV",
      collection_points: "Sammelplätze",
      households: "Haushalte",
    },
  },
  status: "Status",
  weekdays: {
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
    monday_short: "Mo",
    tuesday_short: "Di",
    wednesday_short: "Mi",
    thursday_short: "Do",
    friday_short: "Fr",
    saturday_short: "Sa",
    sunday_short: "So",
  },
  collection_point_details: {
    last_emptying: "letzte Entleerung",
    next_emptying: "nächste Entleerung",
    average_filling_time: "Durchschnittliche Fülldauer",
    container_last_send_warning: "Zuletzt gesendet am {{date}}",
    container_never_send_warning: "Sensor hat noch nie gesendet.",
    fixed_unload_interval: "Manuelles Entleerintervall",
    container_lid_open_since: "Behälter offen seit {{date}}",
    defective_sensor: "Sensor könnte beschädigt sein und sollte geprüft werden.",
    fixed_unload_interval_info: "Füllstand von manuell hinterlegten Abholintervallen abgeleitet.",
    edit: "Bearbeiten",
    full_since: "Vor {{days}} Tagen",
    full_since_more: "Vor >{{days}} Tagen",
    full_in: "In {{days}} Tagen",
    full_in_more: "In >{{days}} Tagen",
    full_heading: "Füllstand 100%",
    filllevel_derived_from_driver_feedback: "Füllstand von Fahrerrückmeldung abgeleitet.",
  },
  navigation: {
    tour_overview: "Tourenübersicht",
    tour_generation: "Tourengenerierung",
    partner_overview: "Partner/Fahrzeuge",
    collection_points: "Sammelinseln",
    dashboard: "Dashboard",
    disposal_merchant_overview: "Entsorger/Verwerter",
    administration: {
      overview: "Verwaltung",
      collection_points: "Sammelinseln",
      user_management: "Benutzer",
    },
    collection_group_overview: "Gruppen",
    household_overview: "Behälter",
    household_discharges: "Entleerungen",
    example: "Beispiel",
    logout: "Abmelden",
    close: "Zuklappen",
    clone: "PRD klonen",
    status: "Klonstatus",
    reset: "System zurücksetzen",
    routes_overview: "Routenverwaltung",
    guidance_administration: {
      overview: "Nachfahrbarkeits Administration",
      driver_locations: "Fahrer Standorte",
    },
    container_warnings: "Behälterwarnungen",
  },
  user_management: {
    heading: "Benutzerverwaltung",
    please_select_user: "Bitte wählen Sie einen Benutzer aus",
    data: {
      nothing_assigned: "Es sind Ihnen keine {{collection}} zugewiesen",
      title: "Benutzerdaten von {{firstName}} {{lastName}}",
      first_name: "Vorname",
      last_name: "Nachname",
      email: "Email",
      role: "Rolle",
      password: "Passwort",
      partner: "Partner",
      create_user: "Anlegen",
      save_user: "Speichern",
      delete_user: "Löschen",
      assign: "{{collection}} Zuweisen",
      password_changed: "Passwort wurde geändert",
      user_created: "Benutzer wurde erstellt",
      user_updated: "Benutzer wurde editiert",
      user_deleted: "Benutzer wurde gelöscht",
      could_not_create_user: "Benutzer konnte nicht erstellt werden",
      could_not_update_user: "Benutzer konnte nicht editiert werden",
      could_not_delete_user: "Benutzer konnte nicht gelöscht werden",
      delete_dialog: {
        heading: "Löschen",
        confirm_text: "Löschen",
        text: "Möchten Sie diesen Benutzer wirklich löschen?",
      },
    },
    new_user: {
      title: "Benutzer erstellen",
    },
    filter: {
      heading: "Filter",
      first_name: "Vorname",
      last_name: "Nachname",
      role: "Rolle",
      apply_filter: "Filter anwenden",
      remove_filter: "Filter zurücksetzen",
    },
  },
  partner_overview: {
    heading: "Partner",
    filter: {
      partner_name: "Name",
    },
    data: {
      title: "Partnerdaten",
      name: "Name",
      street: "Straße",
      postal: "PLZ",
      town: "Ort",
      contact_person: "Ansprechpartner",
      phone_number: "Telefonnummer",
      email: "E-Mail",
      automatic_break: "Pause nach (Stunden)",
      automatic_break_reminder: "Warnung Hinweis x Minuten vor Pause",
      vehicles: "Fahrzeuge",
      show_vehicle: "{{count}} Fahrzeug",
      show_vehicle_plural: "{{count}} Fahrzeuge",
      assign_districts: "Sammelgebiete zuweisen",
      delete_partner: "Partner löschen",
      save_partner: "Speichern",
      create_partner: "Anlegen",
      delete_prompt: "Möchten Sie wirklich den Partner löschen?",
      agree_on_delete: "Ja, löschen!",
      could_not_create: "Partner konnte nicht erstellt werden",
      could_not_update: "Partner konnte nicht aktualisiert werden",
      could_not_delete: "Partner konnte nicht gelöscht werden",
      partner_created: "Partner wurde erfolgreich erstellt",
      partner_updated: "Partner wurde erfolgreich aktualisiert",
      partner_deleted: "Partner wurde erfolgreich gelöscht",
      name_required: "Name ist ein Pflichtfeld",
    },
    errors: {
      required: "Alle erforderlichen Felder(*) müssen ausgefüllt werden.",
    },
    partner_delete_info: "Ein Partner kann nur gelöscht werden, wenn er keinem Benutzer zugewiesen sind.",
    departure_point: {
      heading: "Abfahrtspunkte",
      create_departure_point: "Abfahrtspunkt erstellen",
      delete_dialog_text_departure_point: "Wollen Sie den Abfahrpunkt wirklich löschen?",
      no_departure_points_available_for_collection_partner: "Dieser Partner verfügt über keine Abfahrtspunkte.",
      no_departure_point_assigned: "Nicht zugewiesen",
      not_deletable:
        "Kann nicht gelöscht werden, da dieser Abfahrtspunkt noch mit einem Fahrzeug und/oder einer Tour veknüpft ist.",
    },
    disposal_trailer_location: {
      heading: "Anhängerstandorte",
      create_disposal_trailer_location: "Anhängerstandort erstellen",
      delete_dialog_text_disposal_trailer_location: "Wollen Sie den Anhängerstandort wirklich löschen?",
      no_disposal_trailer_locations_available_for_collection_partner:
        "Dieser Partner verfügt über keine Anhängerstandorte.",
      no_disposal_trailer_location_assigned: "Nicht zugewiesen",
      success: {
        created: "Anhängerstandort erfolgreich angelegt",
        updated: "Anhängerstandort erfolgreich aktualisiert",
        deleted: "Anhängerstandort erfolgreich gelöscht",
      },
      errors: {
        duplicate: "Ein Anhängerstandort mit dem selben Namen, Längen- und Breitengrad existiert bereits",
        required_by_tour_generation:
          "Dieser Anhängerstandort wird in einer aktuellen oder geplanten Routenberechnung benötigt und kann nicht gelöscht werden",
      },
    },
  },
  collection_points: {
    heading: "Übersicht",
    association: "Sammelregion",
    export_containers: "Behälter Exportieren",
    last_send_warning:
      "Mindestens ein Sensor sendet seit über {{days}} Tagen nicht mehr und es ist kein fixes Entleerintervall hinterlegt.",
    open_lid_warning: "Mindestens ein Behälter ist nicht geschlossen.",
    defective_sensor_warning: "Ein oder mehr Sensor/en könnten beschädigt sein.",
    clear: "Löschen",
    warnings: {
      [ContainerWarningType.COLLECTION_POINT_SWITCHED_TO_DRIVER_FEEDBACK]:
        "Sammelinsel wurde für mindestens ein Material automatisch auf Grund von Sensorproblemen auf die Fahrerrückmeldung umgestellt. (seit {{date}}) Bitte Sensoren überprüfen.",
      [ContainerWarningType.NOT_ENOUGH_FEEDBACK_DATA]:
        "Fahrerrückmeldung: für mindestens ein Material sind nicht genügend Daten vorhanden",
    },
    filter: {
      heading: "Filter",
      postal_code: "PLZ",
      container_number: "Behälternr.",
      sensor_id: "Sensor ID",
      address: "Adresse",
      materials: "Materialien",
      apply_filter: "Anwenden",
      remove_filter: "Filter zurücksetzen",
      towns_label: "Gemeinden - GKZ",
      counties_label: "Bezirke",
      only_issue_containers: "nur Warnungen anzeigen",
      filllevel_label: "Füllstand",
      all_filllevels: "alle",
      description: "Bezeichnung",
      reference_date: "Referenzdatum",
      reference_date_info:
        "Durch Anpassung des Datums sehen Sie, wie sich die Füllstände in dem Sammelgebiet verändern. Bereits generierte Touren werden dabei in Betracht gezogen.",
      collection_type: "Sammelinseltyp",
    },
    table: {
      id: "Id",
      description: "Bezeichnung",
      address: "Adresse",
      location_number: "Standortnummer",
      name: "Name",
      district: "Sammelgebiet",
      materials: "Materialien",
      date_next_emptying: "nächste Entleerung",
      date_last_emptying: "letzte Entleerung",
      material: "Material",
      fill_level: "Füllstand",
      count_containers: "{{count}} Behälter",
      count_containers_plural: "{{count}} Behälter",
      number_of_container: "Anzahl der Behälter",
    },
    cards: {
      last_emptying: "Letzte Entleerung",
      next_emptying: "Nächste Entleerung",
      partner: "Partner",
      collection_amount_last_month: "Letzter Monat",
      collection_points: "Sammelinseln",
      total: "Gesamt",
    },
    notifications: {
      critical_battery: "Behälter (#{{container_id}}) - kritischer Batteriezustand",
      offline: "Behälter (#{{container_id}}) hat seit {{days}} Tagen keine Daten gesendet!",
      full: "Behälter (#{{container_id}}) ist voll!",
      critical_filllevel_change:
        "Behälter (#{{container_id}}) verzeichnete am {{date}} einen kritischen Füllstandsprung von {{value}} auf {{additional_field}}!",
    },
    info_cards: {
      region: {
        amount_containers_above_filllevel_treshhold: "Anzahl Behälter über 50%",
        amount_fraction: "letzter Monat",
        amount_material_emptied: "{{material}} entleert {{timeRange}}",
      },
      partners: {
        title: "Partner {{name}}",
        amount_emptied_containers: "Entleerte Behälter",
        amount_hours: "Geleistete Stunden",
        amount_regions: "Sammelregion",
        amount_regions_plural: "Sammelregionen",
        amount_vehicles: "Fahrzeug",
        amount_vehicles_plural: "Fahrzeuge",
      },
    },
    export_dialog: {
      title: "Behälter Export",
      export: "Exportieren",
      filter: "Filter anwenden",
    },
    collection_type: {
      [CollectionType.ON_INTERVAL]: "Fixes Abholintervall",
      [CollectionType.ON_DEMAND]: "Auf Abruf",
      [CollectionType.ON_SENSOR]: "Sensorbasiert",
    },
  },
  tour_overview: {
    heading: "Tour vom",
    heading_prefix_manual: "Manuelle",
    download: "Herunterladen",
    tour_without_containers: "Tour wurde zur Behälterinventarisierung generiert.",
    tour_with_containers: "Tour wurde zur Entleerung von Sammelinseln generiert.",
    manual_tour: "Manuelle Tour",
    in_progress: "Tour wurde noch nicht abgeschlossen",
    planned_duration: "[geplant]",
    actual_duration: "[tatsächlich]",
    collection_points: "Sammelstellen",
    table: {
      mark: "Markierung",
      type: "Typ",
      name: "Bezeichnung",
      address: "Adresse",
      hint: "Hinweis",
      hints: {
        cancelled: "Übersprungen",
        driver_inserted: "vom Fahrer hinzugefügt",
      },
      fill_level: "Füllstand",
      show_images: "Bilder anzeigen",
      no_images: "Kein Bild vorhanden",
      add_collection_point: "Haltepunkt hinzufügen",
    },
    tooltip: {
      location_number: "Standortnummer",
      description: "Bezeichnung",
      last_emptying: "Letzte Entleerung",
      fill_levels: "Füllstand",
      leave_at: "Geplante Uhrzeit",
      completed_at: "Abschlusszeitpunkt",
      vehicle_utilization: "Auslastung LKW",
    },
    defect_containers: {
      head: "Defekte Behälter",
      included: "Verplante Defekte Behälter",
      excluded: "Ausgeschlossene Defekte Behälter",
      table: {
        mark: "Markierung",
        date: "Tourdatum",
        sensor_id: "Sensor ID",
        material: "Fraktion",
        address: "Adresse",
        filllevel: "Füllstand",
        link: "Sammelinsel",
        reason: "Manuelle Abholung",
        error: "Defekte Behälter konnten nicht geladen werden.",
        empty: "Keine defekten Behälter für diese Tour.",
        collection_point_tooltip: "Zu Sammelinsel gehen",
      },
    },
    stop_types: {
      [STOP_TYPE.DEPARTURE_POINT]: "LKW Standplatz",
      [STOP_TYPE.COLLECTION_POINT]: "Sammelinsel",
      [STOP_TYPE.DISPOSAL_MERCHANT]: "Verwerter",
      [STOP_TYPE.TRAILER_LOCATION]: "Anhängerstandort",
    },
    reason: "Manuelle Abholung",
    export_reasons: {
      [ExportReason.EMPTYING_REQUESTED]: "Entleerung angefordert",
      [ExportReason.INTERVAL_DUE]: "Manuelles Abfuhrintervall",
      [ExportReason.MANUALLY_INSERTED]: "-",
      [ExportReason.MANUALLY_INSERTED_BY_DRIVER]: "-",
      [ExportReason.HIGH_FILLLEVEL]: "-",
      [ExportReason.OTHER]: "-",
      interval: "{{interval}} Woche(n)",
      on_demand: "Auf Abruf",
    },
    collection_point: "{{count}} Sammelinsel",
    collection_point_plural: "{{count}} Sammelinseln",
    planned: "geplant",
    actual: "tatsächlich",
    distance_driven: "Gefahrene km",
    duration_warning: "Tour wurde nachträglich bearbeitet.",
    confirm_delete_text: "Wollen Sie die Tour wirklich löschen?",
    tour_deleted: "Tour wurde erfolgreich gelöscht.",
    could_not_delete_tour: "Tour konnte nicht gelöscht werden.",
    tour_export_dialog: {
      title: "Touren export",
      subtitle: "Touren im ausgewählten Zeitraum werden heruntergeladen und in einer Datei gespeichert.",
      time_range: "Zeitraum",
      cancel_btn: "Abbrechen",
      export_btn: "Exportieren",
    },
    details: {
      on_site: "Vor Ort",
      disposal_merchant: "Verwerter",
      warning: "Starke Abweichung! -> Bitte Fahrereingaben und sowie etwaige Sensorik kontrollieren",
    },
    reschedule: "Tour neu planen",
    reschedule_dialog: {
      title: "Tour neu planen",
      text: "Bitte wählen Sie ein neues Datum für die Tour aus.",
      date_picker: {
        label: "Datum",
        min_date_message: "Datum darf nicht in der Vergangenheit liegen",
      },
      confirm_button_text: "Speichern",
      could_not_reschedule_tour: "Tour konnte nicht neu geplant werden.",
      tour_rescheduled: "Tour wurde erfolgreich neu geplant.",
    },
  },
  edit_tour_dialog: {
    title: "Tour bearbeiten",
    cancel: "Abbrechen",
    save: "Speichern",
    confirm_delete_text: 'Wollen Sie die Sammelinsel "{{description}}" wirklich aus der Tour löschen?',
    tour_edited: "Tour wurde erfolgreich aktualisiert.",
    could_not_edit_tour: "Tour konnte nicht aktualisiert werden.",
  },
  add_collection_point_dialog: {
    title: "Haltepunkt hinzufügen",
    cancel: "Abbrechen",
    add: "Hinzufügen",
    description: "Beschreibung",
    address: "Adresse",
    sequence_number: "Markierung",
    materials: "Materialien",
    collection_point: "Sammelpunkt",
    disposal_merchant: "Verwerter",
    materials_error: "Materialien konnten nicht geladen werden.",
    confirm_title: "Haltepunkt #{{id}} hinzufügen",
  },
  disposal_merchant_overview: {
    heading: "Verwerter",
    filter: {
      disposal_merchant_name: "Name",
    },
    data: {
      title: "Verwerterdaten",
      name: "Name",
      street: "Straße",
      postal: "PLZ",
      town: "Ort",
      contact_person: "Ansprechpartner",
      phone_number: "Telefonnummer",
      email: "E-Mail",
      location: "Position",
      delete_disposal_merchant: "Verwerter löschen",
      save_disposal_merchant: "Speichern",
      create_disposal_merchant: "Anlegen",
      delete_prompt: "Möchten Sie wirklich den Verwerter löschen?",
      agree_on_delete: "Ja, löschen!",
      could_not_create: "Verwerter konnte nicht erstellt werden",
      could_not_update: "Verwerter konnte nicht aktualisiert werden",
      could_not_delete: "Verwerter konnte nicht gelöscht werden",
      disposal_merchant_created: "Verwerter wurde erfolgreich erstellt",
      disposal_merchant_updated: "Verwerter wurde erfolgreich aktualisiert",
      disposal_merchant_deleted: "Verwerter wurde erfolgreich gelöscht",
      name_required: "Name ist ein Pflichtfeld",
      material: "Material",
      materials: "Materialien",
      no_materials: "Keine Materialien ausgewählt.",
      opening_hours: "Öffnungszeiten",
      vacation_times: "Urlaubszeiten",
      no_vacation_times: "Keine Urlaubszeiten eingetragen.",
      overlapping_vacation_times: "Urlaubszeiten dürfen sich nicht überlappen",
      district_required: "Sie müssen mindestens ein Sammelgebiet auswählen",
      wrong_towns: "Sie müssen mindestens eine Gemeinde von jedem Sammelgebiet auswählen.",
    },
  },
  vehicle_overview: {
    back_to_partner_overview: "Partnerauswahl",
    partner_not_found: "Partner mit der ID '{{partnerID}}' wurde nicht gefunden",
    data: {
      title: "Fahrzeugdaten",
      district: "Sammelgebiet",
      towns: "Gemeinden",
      emission_standard: "Abgasnorm",
      licence_plate: "Kennzeichen",
      type: "Fahrzeugtyp",
      trailer: "Anhänger",
      length: "Länge [cm]",
      width: "Breite [cm]",
      height: "Höhe [cm]",
      costs_per_hour: "Stundenkosten [€]",
      costs_per_km: "Kilometerkosten [€]",
      scale: "Waage",
      create_vehicle: "Erstellen",
      delete_vehicle: "Fahrzeug löschen",
      save_vehicle: "Speichern",
      vehicle_created: "Fahrzeug wurde erfolgreich erstellt.",
      could_not_create: "Fahrzeug konnte nicht erstellt werden.",
      vehicle_updated: "Fahrzeug wurde erfolgreich aktualisiert.",
      could_not_update: "Fahrzeug konnte nicht aktualisiert werden.",
      vehicle_deleted: "Fahrzeug wurde erfolgreich gelöscht.",
      could_not_delete: "Fahrzeug konnte nicht gelöscht werden.",
      delete_prompt: "Möchten Sie wirklich das Fahrzeug löschen?",
      agree_on_delete: "Ja, löschen!",
      chambers: "Kammern",
      chambers_vehicle: "Kammern Fahrzeug",
      chambers_trailer: "Kammern Anhänger",
      chamber_amount: "Nutzlast [kg]",
      material: "Material",
      no_chambers: "Keine Kammern eingetragen.",
      chamber_required: "Mindestens eine Kammer erforderlich.",
      operating_time: "Einsatzzeit",
      min_tour_duration: "Min. Tourdauer",
      max_tour_duration: "Max. Tourdauer",
      starting_point: "Startplatz",
      end_point: "Endpunkt",
      departure_point_name: "Name",
      latitude: "Latitude",
      longitude: "Longitude",
      driver: "Fahrer",
      no_driver: "Kein Fahrer",
    },
  },
  date_picker: {
    cancel: "Abbrechen",
    clear: "Zurücksetzen",
    ok: "Ok",
    from: "Von:",
    to: "Bis:",
  },
  tour_selector: {
    choose: "Bitte wähle eine Tour aus.",
  },
  example: {
    heading: "Beispiel",
  },
  table: {
    data_loading: "Daten werden geladen",
    no_data: "Keine Daten verfügbar",
    previous: "Zurück",
    next: "Weiter",
    page: "Seite",
    rows: "Einträge",
    of: "von",
  },
  errors: {
    no_access: "Sie haben keinen Zugriff auf das Waste Collection Portal!",
    generic: "Es ist ein unerwarteter Fehler aufgetreten.",
    no_association_dashboard_data: "Es konnten keine Daten für diese Sammelregion geladen werden.",
    no_district_dashboard_data: "Es konnten keine Daten für dieses Sammelgebiet geladen werden.",
    no_next_tour: "Es konnte keine Tour geladen werden.",
    no_association_tours_found:
      "Für diese Sammelregion wurden mit den aktuellen Filtereinstellungen keine Touren gefunden.",
    no_district_tours_found:
      "Für dieses Sammelgebiet wurden mit den aktuellen Filtereinstellungen keine Touren gefunden.",
    wrong_login: "Falsche Email oder falsches Passwort",
    min_date: "Datum muss nach dem Von-Datum liegen.",
  },
  logout: {
    title: "Abmelden",
    text: "Möchten Sie sich wirklich abmelden?",
    open_downloads_text: "Es sind noch Downloads in Bearbeitung. Möchten Sie sich dennoch abmelden?",
  },
  clone_db_request: {
    success:
      "Anfrage erfolgreich. Sie werden in {{timeout}} Sekunden automatisch ausgeloggt. Melden Sie sich in 5 Minuten erneut an.",
    pending: "Ein Klonvorgang ist bereits in Bearbeitung.",
    not_allowed: "Dieses System erlaubt diese Funktion nicht.",
    errors: {
      incompatible: "Die Zieldatenbank ist nicht kompatibel mit diesem System.",
      unknown: "Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es erneut.",
    },
  },
  clone: {
    header:
      "Wollen Sie wirklich das Produktivsystem klonen? Alle Daten des aktuellen Systems werden dabei gelöscht. Dies kann nicht rückgängig gemacht werden und bis zu 5 Minuten dauern.",
  },
  clone_status: {
    success: "Das Produktivsystem wurde zuletzt am {{date}} erfolgreich geklont.",
    pending: "Das Produktivsystem wird seit {{date}} geklont. Dies kann bis zu 5 Minuten dauern.",
    delete_tourgenerations_failed_warning:
      "Ein unerwarteter Fehler verhinderte das Abbrechen geplanter Tourgenerationen. Bitte prüfen Sie manuell auf geplante Tourgenerationen.",
    scheduled_tours_left_warning:
      "Eine oder mehrere geplante Tourgenerationen konnten nicht abgebrochen werden. Bitte prüfen Sie manuell auf geplante Tourgenerationen.",
    null: "Das Produktivsystem wurde noch nie geklont.",
    error: "Ein Fehler ist während der Klonung des Produktivsystems aufgetreten.",
    code: "Code",
  },
  reset: {
    header: "Wollen Sie wirklich das System zurücksetzen?",
    success:
      "Anfrage erfolgreich. Sie werden in {{timeout}} Sekunden automatisch ausgeloggt. Melden Sie sich in 5 Minuten erneut an.",
  },
  map: {
    search: "Adresse suchen...",
    loading: "Laden...",
  },
  image_types: {
    collection_point: "Sammelinsel",
    documentation: "Dokumentation",
    weighing: "Wiegeschein",
  },
  weighings: {
    gross: "Brutto",
    tare: "Tara",
    net: "Netto",
    number: "Wiegescheinnummer",
  },
  collection_point_administration: {
    collection_points: "Sammelinseln",
    create_collection_point: "Sammelinsel anlegen",
    collection_point_info: "Sammelinsel Info",
    delete_collection_point: "Sammelinsel löschen",
    edit_collection_point_opening_hours: "Öffnungszeiten bearbeiten",
    collection_point_settings: "Einstellungen",
    accessible_with_trailer: {
      label: "Mit Hänger erreichbar",
      tooltip: "Falls aktiviert, kann diese Sammelstelle auch mit angekoppeltem Hänger angefahren und verplant werden",
    },
    avoid_overfill: {
      label: "Überfüllung vermeiden",
      tooltip:
        "Falls aktiviert, wird versucht, unabhängig von gewählten Optimierungsprofilen, die Überfüllung dieser Sammelstelle zu vermeiden",
    },
    data: {
      description: "Bezeichnung",
      location_number: "Standortnummer",
      cadastral_name: "Katastralgemeinde",
      cadastral_number: "Katastralgemeinde Nummer",
      town: "Gemeinde - GKZ",
      street: "Straße",
      postal: "PLZ",
      place: "Ort",
      create: "Anlegen",
      save: "Speichern",
      cancel: "Abbrechen",
      manufacturer: "Hersteller",
      sensor_id: "Sensor ID",
      material: "Material",
      number: "Behälternr.",
      container_type: "Behältertyp",
      emptying_types: {
        [EmptyingType.LIFTING]: "Hub",
        [EmptyingType.POUR]: "Schütt",
        [EmptyingType.LIFTING_OEKOBELL]: "Hub Ökobell",
        [EmptyingType.LIFTING_OEKOLINE]: "Hub Ökoline",
        [EmptyingType.UF]: "UF",
      },
      fixed_unload_interval: "Fixes Abfuhrintervall",
      fixed_interval: "Intervall fixieren",
      fixed_interval_info:
        "Falls diese Checkbox aktiviert ist, wird die Sammelstelle starr gemäß dem festgelegten Abfuhrintervall verplant.",
      unload_interval_on_demand: "Auf Abruf",
      request_emptying: "Entleerung angefordert",
      extrapolation_weighting: "Gewichtungen",
      extrapolation_weighting_info:
        "Der tägliche Füllstandzuwachs wird zu diesen Prozentpunkten angewandt. Der absolute wöchentlich Füllstandzuwachs wird dadurch nicht verändert.",
      opening_hours: {
        title: "Öffnungszeiten bearbeiten",
      },
    },
    unit: {
      week: "{{count}} Woche",
      week_plural: "{{count}} Wochen",
    },
    errors: {
      required: "Alle erforderlichen Felder(*) müssen ausgefüllt werden.",
      cadastral_number_invalid: "Katastralgemeinden Nummer darf nur aus Ziffern bestehen",
      postal_invalid: "PLZ darf nur aus Ziffern bestehen",
      weighting_invalid:
        "Gewichtungen müssen größer oder gleich Null sein. Mindestens eine Gewichtung muss größer als Null sein.",
      opening_hour_difference_invalid: "Von und Bis müssen mindestens eine Stunde auseinanderliegen.",
      opening_hour_upsert_error: "Öffnungszeiten konnten nicht gespeichert werden!",
      template_file_error: "Vorlage konnte nicht heruntergeladen werden!",
    },
    collection_point_updated: "Sammelinsel wurde erfolgreich aktualisiert.",
    collection_point_created: "Sammelinsel wurde erfolgreich angelegt.",
    collection_point_deleted: "Sammelinsel wurde erfolgreich gelöscht.",
    could_not_create_collection_point: "Sammelinsel konnte nicht erstellt werden",
    could_not_update_collection_point: "Sammelinsel konnte nicht aktualisiert werden",
    could_not_delete_collection_point: "Sammelinsel konnte nicht gelöscht werden",
    delete_dialog_text_collection_point: "Wollen Sie die Sammelinsel wirklich löschen?",
    delete_dialog_text_container: "Wollen Sie den Behälter wirklich löschen?",
    images: {
      add: "Dokufotos hinzufügen",
      view: "Ansehen",
      dropzone_text: "Dateien hierherziehen oder klicken, um Dokufotos hochzuladen",
      cancel: "Abbrechen",
      upload: "Hochladen",
      images_uploaded: "Bilder wurden erfolgreich hochgeladen.",
      could_not_upload_images: "Bilder konnten nicht hochgeladen werden.",
    },
    create_container: "Behälter anlegen",
    delete_container: "Behälter löschen",
    change_container: "Behälter tauschen",
    change: "Tauschen",
    confirm_change: "Ja, tauschen",
    confirm_change_heading: "Wirklich tauschen?",
    container_created: "Behälter wurde erfolgreich angelegt.",
    container_updated: "Behälter wurde erfolgreich aktualisiert.",
    container_deleted: "Behälter wurde erfolgreich gelöscht.",
    container_changed: "Behälter wurde erfolgreich getauscht.",
    could_not_create_container: "Behälter konnte nicht erstellt werden.",
    could_not_update_container: "Behälter konnte nicht aktualisiert werden.",
    could_not_delete_container: "Behälter konnte nicht gelöscht werden.",
    could_not_change_container: "Behälter konnte nicht getauscht werden.",
    upload: {
      upload_successful: "Sammelinseln wurden erfolgreich importiert.",
      upload_collection_points: "Upload Liste",
      dropzone_text: "Dateien hierherziehen oder klicken, um Sammelinseln hochzuladen",
      dropzone_underline_text: "Noch kein Template? Laden Sie die neuste <0>Vorlage hier herunter.</0>",
      upload_not_possible: "Upload nicht möglich",
      collection_point_count: "{{collection_points_count}} Sammelstellen",
      import: "Importieren",
      cancel: "Abbrechen",
      empty: "Ihre Eingabe beinhaltet keine Sammelinseln!",
      errors: {
        invalid_format:
          "Bitte stellen Sie sicher, dass Sie das neueste Upload Template verwenden und alle Pflichtfelder ausgefüllt haben.",
        invalid_container_type_info: "Behälterdaten nicht unterstützt. Bitte wenden Sie sich an den Support.",
        invalid_accessible_times:
          "Zufahrtszeiten von Sammelstellen weisen ein nicht lesbares Format auf. Bitte kontrollieren Sie die Einträge und versuchen Sie es erneut!",
        invalid_coordinates:
          "GPS Koordinaten fehlen oder sind fehlerhaft. Bitte überprüfen Sie die Eingaben und versuchen Sie es erneut.",
        connected_district_not_found:
          "Zur ausgewählten Sammelregion konnte kein passendes Sammelgebiet gefunden werden. Bitte wenden Sie sich an den Support.",
        connected_association_not_found:
          "Zum ausgewählten Sammelgebiet konnte keine passende Sammelregion gefunden werden. Bitte wenden Sie sich an den Support.",
        unkown_error: "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
      },
      table: {
        description: "Bezeichnung",
        street: "Straße",
        town: "Gemeinde",
        latitude: "Lat",
        longitude: "Long",
        number_of_containers: "# Behälter",
      },
      duplicate_dialog: {
        title: "Sammelinseln Hochladen oder Überspringen?",
        body: `Die Sammelinsel {{description}} mit Koordinaten ({{latitude}}/{{longitude}}) existiert bereits für das Sammelgebiet. Wie soll fortgefahren werden?`,
        options: {
          exclude: "Diesen Eintrag überspringen",
          include: "Hochladen und erweitern: Sammelstelle wird um Behälter erweitert",
        },
        remember_action: "Für alle Einträge merken",
      },
      unsaved_data_prompt: {
        title: "Upload Abbrechen",
        content:
          "Der Upload Vorgang ist nicht vollständig abgeschlossen.\nBeim Abbrechen des Vorgangs gehen Daten verloren.\n\nMöchten Sie den Vorgang trotzdem unterbrechen?",
      },
      back: "Zurück",
    },
  },
  routes_management: {
    title: "Routenverwaltung",
    import_routes: "Routen importieren",
    dropzone_text: "Dateien hierherziehen oder klicken, um Routen hochzuladen",
    cancel: "Abbrechen",
    import: "Importieren",
    filename: "Dateiname",
    name: "Tourname",
    routes_uploaded: "Routen wurden erfolgreich hochgeladen.",
    route_deleted: "Route wurde erfolgreich gelöscht.",
    could_not_upload_routes: "Routen konnten nicht hochgeladen werden.",
    could_not_delete_route: "Route konnte nicht gelöscht werden.",
    delete_dialog_text_route: "Wollen Sie die Route wirklich löschen?",
    replace_routes_title: "Bestehende Routen ersetzen",
    replace_routes_text: "Wollen Sie folgende Routen ersetzen?",
    replace: "Ersetzen",
    add_stop_info: "Stop Info erfolgreich gespeichert",
    delete_stop_info: "Stop Info erfolgreich gelöscht",
    confirm_delete_stop_info_text: "Wollen Sie die Info wirklich löschen?",
    choose_location: "Standort",
    delete: "Löschen",
    open_images: "Fotos öffnen",
    no_images: "keine Fotos vorhanden",
    location_select: {
      success: "Standort geändert",
      error: "Standort konnte nicht geändert werden",
    },
    table: {
      id: "ID",
      filename: "Dateiname",
      name: "Tourname",
      date: "Datum",
      stopName: "Stop",
      lat: "Länge",
      long: "Breite",
      eventType: "Typ",
      info: "Info",
      sequence_number: "Reihenfolge",
      images: "Fotos",
      empty: "Route ist leer.",
    },
    errors: {
      could_not_load: "Beim Laden ist ein Fehler aufgetreten",
      no_relevant_info: "Information konnte nicht angezeigt werden",
      no_info_provided: "Fehlerhafte Daten!",
      add_stop_info: "Fehler beim speichern der Stop Info",
      delete_stop_info: "Fehler beim löschen der Stop Info",
    },
  },
  guidance_administration: {
    driver_location_create: "Neuer Fahrer Standort",
    driver_location_save: "Update Fahrer Standort",
    driver_location_delete: "Lösche Fahrer Standort",
    driver_location_delete_error: "Fehler beim Löschen des Fahrer Standort",
    driver_location: {
      title: "Fahrer Standorte",
      data: {
        name: "Name",
        name_required: "Name fehlt",
      },
    },
    action: {
      create: "Erstellen",
      save: "Speichern",
      cancel: "Abbrechen",
    },
    driver_location_updated: "Fahrer Standort erfolgreich aktualierst.",
    driver_location_created: "Fahrer Standort erfolgreich erstellt.",
    driver_location_deleted: "Fahrer Standort erfolgreich gelöscht.",
    driver_location_create_error: "Fahrer Standort konnte nicht erstellt werden.",
    driver_location_update_error: "Fahrer Standort konnte nicht aktualisiert werden.",
    could_not_delete_driver_location: "Fahrer Standort konnte nicht gelöscht werden.",
    delete_dialog_text_driver_location: "Möchtest du den Fahrer Standort wirklich löschen?",
    filter_driver_location: "Routen für Fahrer Standort filtern:",
  },
  delete_dialog: {
    no: "Nein",
    agree_on_delete: "Ja, löschen",
  },
  user_role: {
    [UserRole.SUPER_ADMIN]: "Superadmin",
    [UserRole.TOWN_ADMIN]: "Gemeindeadmin",
    [UserRole.DRIVER]: "Fahrer",
    [UserRole.ASSOCIATION]: "Verband",
    [UserRole.COLLECTION_PARTNER]: "Sammelpartner",
    [UserRole.GUIDANCE_ADMIN]: "Nachfahrbarkeitsadmin",
    [UserRole.GUIDED_DRIVER]: "Nachfahrer",
    [UserRole.RFID_APP]: "RFID App",
    all: "Alle",
  },
  departure_point_context: {
    errors: {
      could_not_load: "Depots konnten nicht geladen werden",
    },
  },
  departure_point: {
    data: {
      name: "Name",
      lat: "Lat",
      long: "Long",
      search: "Suche",
    },
  },
  disposal_trailer_location_context: {
    errors: {
      could_not_load: "Anhängerstandorte können nicht geladen werden",
    },
  },
  disposal_trailer_location: {
    data: {
      name: "Name",
      lat: "Lat",
      long: "Long",
      search: "Suche",
    },
  },
  location_type: {
    [LocationType.ASSOCIATION]: "Sammelregion",
    [LocationType.DISTRICT]: "Sammelgebiet",
    [LocationType.TOWN]: "Gemeinde",
    [LocationType.LOCATION]: "Fahrer Standort",
    [`${LocationType.ASSOCIATION}_plural`]: "Sammelregionen",
    [`${LocationType.DISTRICT}_plural`]: "Sammelgebiete",
    [`${LocationType.TOWN}_plural`]: "Gemeinden",
    [`${LocationType.LOCATION}_plural`]: "Fahrer Standorte",
  },
  overfilled_days_chart: {
    days: "Tage",
    average_days: "Ø {{days}} Tage",
    average_days_label: "Ø Überfüllungstage",
    days_overfilled: "Überfüllungstage",
  },
  top_overfilled_collection_points_chart: {
    collection_point_info: "Sammelinsel #{{id}}: {{days}} Tage",
    days_label: "Überfüllungstage",
    title: "Sammelinseln mit den meisten Überfüllungstagen",
  },
  emptyings_per_hour_chart: {
    emptyings_per_hour: "Behälterentleerungen/Std.",
    average_emptyings_label: "Ø Entleerungen/Stunde",
    average_emptyings: "Ø {{emptyings}} Entleerungen",
    emptyings: "Entleerungen",
  },
  collected_amount_per_hour_chart: {
    collected_amount_per_hour: "Gesammelte Menge(t)/Std.",
    average_collected_amount_label: "Ø t/Stunde",
    average_collected_amount: "Ø {{collected_amount}}t",
  },
  container_warnings: {
    table: {
      container_number: "Behälternr.",
      sensor_id: "Sensor ID",
      material: "Fraktion",
      description: "Bezeichnung",
      address: "Adresse",
      status: "Status",
      link: "Link",
      message: "Warnung",
      error: "Warnungen konnten nicht abgerufen werden",
      update_error: "Status konnte nicht aktualisiert werden",
      update_success: "Status aktualisiert",
      empty: "Derzeit gibt es keine Warnungen",
      collection_point_tooltip: "Zu Sammelinsel gehen",
    },
    message: {
      [ContainerWarningType.LID_OPEN]: "Behälter offen seit {{date}}",
      [ContainerWarningType.NO_RECENT_SENSOR_DATA]: "Sensor zuletzt gesendet am {{date}}",
      [ContainerWarningType.DEFECTIVE_SENSOR]: "Sensor könnte beschädigt sein und sollte geprüft werden.",
      [ContainerWarningType.COLLECTION_POINT_SWITCHED_TO_DRIVER_FEEDBACK]:
        "Sammelinsel wurde automatisch auf Grund von Sensorproblemen auf die Fahrerrückmeldung umgestellt. (seit {{date}}) Bitte Sensoren überprüfen.",
      [ContainerWarningType.NOT_ENOUGH_FEEDBACK_DATA]: "Fahrerrückmeldung: nicht genügend Daten vorhanden",
    },
    status: {
      [ContainerWarningStatus.OPEN]: "Offen",
      [ContainerWarningStatus.PROGRESS]: "In Bearbeitung",
      [ContainerWarningStatus.DONE]: "Abgeschlossen",
    },
  },
  tour_generation: {
    collection_group: {
      select: "Sammelgruppe auswählen",
      tooltip: "Sammelgruppen",
    },
    heading: "Tourengenerierung",
    materials_invalid: "Die Materialien der Fahrzeuge sind nicht bei allen Einträgen korrekt konfiguriert. (>0kg)",
    use_custom_point: "benutzerdefinierten Start- oder Endplatz verwenden",
    use_custom_operating_times: "benutzerdefinierte Einsatzzeiten verwenden",
    reference_date_info:
      "Das Referenzdatum gibt den Tag der nächsten Tour an und muss zeitlich nach dem letzten Tag der aktuellen Tour sein.",
    considered_tour_days_info:
      'Anzahl der vergangenen Tage, an denen Touren berücksichtigt werden sollen. Wenn an einem dieser Tage ein Container entleert wurde und der Sensor keinen Füllstand gesendet hat, wird der Füllstand auf "0" gesetzt.',
    feature_not_enabled: "Funktion nicht freigeschaltet",
    feature_not_enabled_info:
      "Tourenoptimierung ist nicht für Ihren Zugang freigeschaltet. Bitte kontaktieren Sie den Support.",
    contact_support: "Support kontaktieren",
    data: {
      parameters: "Tourparameter",
      tour_without_containers: "Tour zur Behälterinventarisierung generieren",
      first_week: "1. Abfuhrwoche",
      second_week: "2. Abfuhrwoche",
      threshold: "Threshold",
      threshold_min: "Threshold Min.",
      threshold_2nd_week: "Threshold 2. Woche",
      driving_days: "Fahrtage",
      generate_tours: "Touren generieren",
      tour_is_being_generated: "Tour wird berechnet – bitte kurz warten.",
      tour_is_scheduled: "Die Tour wurde erfolgreich eingeplant.",
      tour_generated_successfully: "Tour erfolgreich abgeschlossen!",
      show_tour: "Tour anzeigen",
      to_tour_generation: "Zur Tourgenerierung",
      error: "Fehler",
      error_try_again: "Tour konnte nicht generiert werden. Bitte versuchen Sie es später erneut oder kontaktieren Sie den Support.",
      tour_generation_successfully: "Tourgenerierung wurde erfolgreich gestartet.",
      could_not_generate_no_recyclers:
        "Für das ausgewählte Sammelgebiet ist kein Verwerter hinterlegt - eine Tourgenerierung ist nicht möglich",
      could_not_generate: "Tourgenerierung konnte nicht gestartet werden.",
      materials: "Abzuholende Materialien",
      material_number: "Material {{number}}",
      no_materials_selected: "keine Materialien ausgewählt",
      no_vehicles_available: "Für diese Materialauswahl sind keine Fahrzeuge verfügbar",
      vehicles: "Fahrzeuge",
      version: "Version",
      max_termination_time: "max. Rechendauer",
      max_iterations: "max. Iterationen",
      schedule_date: "Startzeitpunkt",
      reference_date: "Referenzdatum",
      considered_tour_days: "Berücksichtigte Tourtage",
      disposal_trailer_locations: "Anhängerstandorte",
      tour_generation_in_past:
        "Mindestens ein Einsatztag der zu berechnenden Tour liegt in der Vergangenheit. Soll die Tourengenerierung trotzdem fortgesetzt werden?",
      attention: "Achtung",
      continue_tour_generation: "Weiter zur Generierung",
      error_message: "Bitte maximal {{number}} Anhängerstandorte auswählen.",
    },
    table: {
      first_week: "1. Abfuhrwoche",
      calendar_week_formatted: "{{year}} KW{{week}}",
      district: "Sammelgebiet",
      week_count: "Wochenintervall",
      threshold: "Threshold",
      driving_days: "Fahrtage",
      status: "Status",
      details: "Details",
      generation_started: "Berechnung gestartet um: {{date}}",
      generation_scheduled: "Berechnung geplant um: {{date}}",
      actions: "Aktionen",
      materials: "Materialien",
      version: "Version",
      reference_date: "Referenzdatum",
    },
    preview_table: {
      id: "ID",
      location_number: "Standortnummer",
      description: "Beschreibung",
      address: "Adresse",
      fixed_unload_interval: "Fixes Abfuhrintervall",
      fixed_unload_interval_weeks: "{{count}} Woche",
      fixed_unload_interval_weeks_plural: "{{count}} Wochen",
      materials: "Materialien (Stand: {{date}})",
      last_emptying: "Letzte Entleerung: {{date}}",
      collection_point_status: "Status",
      status: {
        [CollectionPointStatus.added]: "Hinzugefügt",
        [CollectionPointStatus.removed]: "Entfernt",
        [CollectionPointStatus.planned]: "Geplant",
      },
      info_text:
        "Zeigt Sammelpunkte, die zum ersten Fahrtag einen Füllstand des eingestellten minimalen Füllstands ({{thresholdMin}}%) aufweisen. Die finale Route kann abweichen.",
      past_days_container_view_info_text:
        "Für Tourgenerierungen vergangener Tage kann keine Behälteransicht angezeigt werden.",
    },
    confirm_dialog: {
      cancel: "Abbrechen",
      generate: "Generieren",
      schedule: "Später Generieren",
      title: "Tour generieren",
      collection_point_info: "{{count}} Sammelinsel",
      collection_point_info_plural: "{{count}} Sammelinseln",
      materials: "Materialien",
      confirm_title: "Wollen Sie wirklich die Tour generieren?",
      confirm_text: "Die Tourgenerierung kann bis zu 12 Stunden dauern.",
      ok: "Ok",
      export: "Download Behälterauswahl",
    },
    tour_parameters_dialog: {
      title: "Details",
      ok: "Ok",
      data: {
        district: "Sammelgebiet",
        calculation_start: "Berechnungsstart",
        calculation_end: "Berechnungsende",
        version: "Version",
        threshold: "Threshold",
        threshold_min: "Threshold min.",
        departure_point: "Start: {{departure_point}}",
        end_point: "Ende: {{departure_point}}",
      },
    },
    preview_dialog: {
      title: "Vorschau",
      collection_point_info_planned: "{{count}} Sammelinsel geplant",
      collection_point_info_planned_plural: "{{count}} Sammelinseln geplant",
      collection_point_info_actual: "{{count}} Sammelinsel vom Tourenoptimierungsalgorithmus berechnet",
      collection_point_info_actual_plural: "{{count}} Sammelinseln vom Tourenoptimierungsalgorithmus berechnet",
      materials_info_planned: "Geplant: {{materials}}",
      materials_info_actual: "Berechnet: {{materials}}",
      ok: "Ok",
      filter: {
        added_collection_points: "Durch Tourenoptimierungsalgorithmus hinzugefügte Sammelinseln",
        removed_collection_points: "Vom Tourenoptimierungsalgorithmus nicht berücksichtigte Sammelinseln",
        planned_collection_points: "Ursprüngliche geplante Sammelinseln",
      },
    },
    disposal_merchant_availability_dialog: {
      no_available_disposal_merchants_for_single_day:
        "Für {{day}} scheint es aktuell keinen passenden Verwerter zu geben. Sie können die Verwerter Einstellungen ändern oder die Tourenparameter anpassen. Drücken Sie hierzu Abbrechen.",
      no_available_disposal_merchants_for_several_days:
        "Für mehrere Tage der zu berechnenden Tour scheint es aktuell keinen passenden Verwerter zu geben. Sie können die Verwerter Einstellungen ändern oder die Tourenparameter anpassen. Drücken Sie hierzu Abbrechen.",
      customize_unloading_point: "Verwerter anpassen",
    },
    actions: {
      regenerate: "Tour erneut generieren",
      show_details: "Details anzeigen",
      abort_calculation: "Berechnung abbrechen",
      abort_scheduled_calculation: "Geplante Berechnung löschen",
      delete_tour_generation: "Tourgenerierung löschen",
    },
    abort_calculation: {
      heading: "Berechnung abbrechen?",
      text: "Wollen Sie die Berechnung wirklich abbrechen?",
      abort_calculation: "Berechnung abbrechen",
      abort_successfully: "Berechnung wurde erfolgreich abgebrochen.",
      could_not_abort: "Berechnung konnte nicht abgebrochen werden.",
      continue_calculation: "Berechnung fortsetzen",
    },
    delete_generation: {
      heading: "Tourgenerierung löschen?",
      text: "Wollen Sie die Tourgenerierung wirklich abbrechen?",
      delete_generation: "Tourgenerierung löschen",
      delete_generation_successful: "Tourgenerierung wurde erfolgreich gelöscht.",
      could_not_delete: "Tourgenerierung konnte nicht gelöscht werden.",
    },
    schedule_dialog: {
      title: "Tourgenerierung planen",
      max_termination_time_info: "Die maximale Rechendauer darf zwischen 15 Minuten und 24 Stunden betragen.",
      schedule_date_info: "Der Startzeitpunkt muss in der Zukunft liegen.",
      confirm: "Planen",
    },
    cancel_scheduled_calculation: {
      heading: "Geplante Berechnung löschen?",
      text: "Wollen Sie die geplante Berechnung wirklich abbrechen?",
      delete: "Löschen",
      cancelled_successfully: "Geplante Berechnung wurde erfolgreich abgebrochen.",
      could_not_cancel: "Berechnung konnte nicht abgebrochen werden.",
    },
    concurrent_tour_dialog: {
      title: "Tourüberschneidung",
      description: "Die zu generierende Tour überschneidet sich mit einer fertig berechneten und/oder geplanten Tour.",
      replace_concurrent_tour: "Bestehende Tourengenerierung löschen",
      keep_concurrent_tour: "Beide Touren behalten",
    },
  },
  week_picker: {
    calendar_week: "Kalenderwoche",
    calendar_week_formatted: "KW{{week}} ({{year}})",
    calendar_week_short: "KW{{week}}",
    week_number: "Woche {{week}}",
    week_number_short: "W{{week}}",
  },
  date_format: "DD.MM.YYYY",
  date_time_format: "DD.MM.YYYY HH:mm",
  date_time_input_format: "DD.MM.YYYY HH:mm",
  day_of_month_format: "DD. MMM",
  date_time_seconds_format: "DD.MM.YYYY HH:mm:ss",
  custom_autocomplete: {
    selectAll: "Alle auswählen",
    deselectAll: "Alle abwählen",
    clearSelection: "Alle abwählen",
    tagText: "ausgewählt",
  },
  validation: {
    time: {
      notpossible: "Eingegebene Uhrzeit ist ungültig",
    },
  },
  export: {
    download_error: "Datei konnte nicht heruntergeladen werden.",
    cancel_dialog: {
      heading_all: "Downloads abbrechen",
      text_all: "Möchten Sie wirklich alle Downloads abbrechen?",
      heading_single: "Download abbrechen",
      text_single: "Möchten Sie den Download wirklich abbrechen?",
    },
    download_item: {
      queued: "in Warteschlange",
      processing: "in Bearbeitung",
      finished: "Abgeschlossen",
      error: "Ein unbekannter Fehler ist aufgetreten",
    },
    downloads_finished: "Downloads abgeschlossen",
    preparing_downloads: "Downloads werden vorbereitet",
    unknown_error: "Ein unbekannter Fehler ist aufgetreten.",
  },
  households: {
    filter: {
      heading: "Filter",
      counties: "Bezirke",
      address: "Adresse",
      rfid: "RFID",
      materials: "Materialien",
      towns: "Gemeinden - GKZ",
      show_warnings: "nur Warnungen anzeigen",
      reset_filter: "Filter zurücksetzen",
    },
    table: {
      id: "ID",
      address: "Adresse",
      material: "Material",
      container_type: "Behältertyp",
      comment: "Kommentar",
      rfid: "RFID",
      no_data: "Für diese Filtereinstellungen konnten keine Daten gefunden werden.",
      container_details: "Behälterübersicht",
    },
    comment_types: {
      [HouseholdContainerCommentType.CONTAINER_OLD]: "Behälter alt",
      [HouseholdContainerCommentType.CONTAINER_NOT_PROVIDED]: "Behälter nicht bereitgestellt",
      [HouseholdContainerCommentType.CONTAINER_DEFECTIVE]: "Behälter defekt",
    },
  },
  household_overview: {
    status: {
      collection_points: "Sammelinseln",
      total: "Gesamt",
      amount_chambers: "Anzahl Kammern",
      amount_containers_chipped: "Behälter verchippt",
    },
  },
  household_details: {
    delete_container: "Behälter abziehen",
    edit_container: "Behälter bearbeiten",
    back_to_overview: "Zurück zur Übersicht",
    support_id: "Support ID",
    status: "Status",
    rfid: "RFID",
    material: "Material",
    type: "Behältergröße",
    active: "Aktiv",
    street: "Straße",
    number: "Hausnummer",
    postal: "PLZ",
    place: "Ort",
    household: "Haushalt",
    container: "Behälter",
    container_error: "Behälter konnte nicht geladen werden",
    edit_error: "Beim Speichern der Änderungen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    delete_dialog: {
      heading: "Wollen Sie den Behälter wirklich abziehen?",
    },
    edit_dialog: {
      confirm: "Bestätigen",
    },
    table: {
      type: "Typ",
      date: "Datum",
      time: "Uhrzeit",
      field: "Feld",
      old_value: "Alter Wert",
      new_value: "Neuer Wert",
      update: "Update",
      field_types: {
        material_id: "Material",
        type_id: "Behältergröße",
        comment: "Kommentar",
        rfid: "RFID",
      },
      comment_types: {
        [HouseholdContainerCommentType.CONTAINER_OLD]: "Behälter alt",
        [HouseholdContainerCommentType.CONTAINER_NOT_PROVIDED]: "Behälter nicht bereitgestellt",
        [HouseholdContainerCommentType.CONTAINER_DEFECTIVE]: "Behälter defekt",
      },
    },
  },
  household_discharges: {
    discharges: "Entleerungen",
    heading: "Tour vom",
    table: {
      time: "Uhrzeit",
      address: "Adresse",
      chip_nr: "Chip Nr. (hex)",
      warning: "Warnung",
      volume: "Volumen",
      weight: "Gewicht",
      delete: "Löschen",
      container_details: "Behälterdetails",
      unknown_rfid: "RFID unbekannt",
      warning_filter: "nur Warnungen anzeigen",
    },
    export: "{{format}} Download",
    delete_dialog: {
      delete: "Löschen",
      cancel: "Abbrechen",
      title: "Schüttung löschen?",
      text: "Wollen Sie die Schüttung wirklich löschen? Die Reihenfolge aller folgender Schüttungen wird aktualisiert. Diese Aktion kann nicht rückgängig gemacht werden.",
      success: "Schüttung wurde erfolgreich gelöscht.",
    },
    filter: {
      apply: "Anwenden",
      remove: "Filter zurücksetzen",
    },
    number_of_containers: "{{count}} Behälter",
    number_of_warnings: "{{count}} Warnungen",
    info_window: {
      household: {
        household: "Haushalt",
        id: "ID",
        street: "Straße",
        place: "Ort",
      },
      household_container: {
        container: "Haushaltsbehälter",
        id: "Id",
        rfid: "RFID",
        comment: "Kommentar",
        material: "Material",
      },
      household_discharge: {
        discharge: "Haushaltsentleerung",
        id: "ID",
        tour_id: "Tour ID",
        sequence: "Nummer",
        rfid_hex: "RFID (hex)",
        rfid_dec: "RFID (dez)",
        weight: "Gewicht",
      },
    },
  },
  collection_group_overview: {
    heading: "Gruppen",
    description: "Hier können Gruppen angelegt werden, die auch bei Tourenplanungen verwendet werden können.",
    new_group: "Neue Gruppe",
    details: "Details",
    table: {
      name: "Name",
      district: "Sammelgebiet",
      materials: "Materialien",
      no_data: "Keine Daten verfügbar",
      edit: "Gruppe bearbeiten",
    },
  },
  collection_groups: {
    mutate_page: {
      header: "Details",
      collection_district: "Sammelgebiet",
      collection_district_desc: "Wähle das Sammelgebiet aus, welchem die Gruppe zugewiesen werden soll.",
      group_label: "Gruppenbezeichnung",
      group_label_desc: "Füge hier die Gruppenbezeichnung ein.",
      materials: "Materialien",
      materials_desc:
        "Wähle die Materialien aus, die zur Gruppe gehören und entsprechend berücksichtigt werden sollen.",
      materials_selection: "Auswahl",
      no_materials: "Ihrem Benutzer sind keine Materialien zugewiesen.",
      collection_points: "Sammelstellen",
      please_select_materials: "Wählen Sie ein Material aus.",
      all_selected: "Alle Materialien ausgewählt.",
      address: "Adresse",
      description: "Bezeichnung",
      town: "Gemeinde",
      groups: "Gruppen",
      complete: "Abschließen",
      confirm_delete_material:
        "Achtung! Sammelstellen dieser Gruppe, die nur aus {{material}}-Behältern bestehen, werden aus der Gruppe entfernt.",
      confirm_change_district:
        "Achtung! Diese Aktion setzt alle Einstellungen zurück und die Gruppe muss komplett neu konfiguriert werden. Sind Sie sicher, dass Sie das Sammelgebiet ändern möchten?",
      search: "Suche nach Adresse, Beschreibung",
    },
  },
  unsaved_data_prompt: {
    title: "Achtung!",
    content:
      "Wenn Sie die Seite verlassen, gehen alle ungespeicherten Änderungen verloren. Möchten Sie die Seite wirklich verlassen?",
  },
}

// Overwrite translation for industry users
export const de_industry = {
  association: "Standort",
  district: "Standort",
  collection_points: {
    association: "Standort",
    info_cards: {
      partners: {
        amount_regions: "Standort",
        amount_regions_plural: "Standorte",
      },
    },
    filter: {
      town_label: "Bereich - Bereichsnr.",
    },
  },
  partner_overview: {
    data: {
      assign_districts: "Standort zuweisen",
    },
  },
  vehicle_overview: {
    data: {
      district: "Standort",
    },
  },
  tour_overview: {
    stop_types: {
      disposal_merchant: "Abladestelle",
    },
  },
  disposal_merchant_overview: {
    heading: "Abladestelle",
    data: {
      title: "Abladestellendaten",
      delete_disposal_merchant: "Abladestelle löschen",
      delete_prompt: "Möchten Sie wirklich die Abladestelle löschen?",
      could_not_create: "Abladestelle konnte nicht erstellt werden",
      could_not_update: "Abladestelle konnte nicht aktualisiert werden",
      could_not_delete: "Abladestelle konnte nicht gelöscht werden",
      disposal_merchant_created: "Abladestelle wurde erfolgreich erstellt",
      disposal_merchant_updated: "Abladestelle wurde erfolgreich aktualisiert",
      disposal_merchant_deleted: "Abladestelle wurde erfolgreich gelöscht",
      invalid_opening_hours: "Öffnungszeiten enthalten ungültige Eingaben",
    },
  },
  errors: {
    no_association_dashboard_data: "Es konnten keine Daten für diesen Standort geladen werden.",
    no_association_tours_found:
      "Für diesen Standort wurden mit den aktuellen Filtereinstellungen keine Touren gefunden.",
    no_district_dashboard_data: "Es konnten keine Daten für diesen Standort geladen werden.",
    no_district_tours_found: "Für diese Standort wurden mit den aktuellen Filtereinstellungen keine Touren gefunden.",
  },
  location_type: {
    [LocationType.ASSOCIATION]: "Standort",
    [`${LocationType.ASSOCIATION}_plural`]: "Standorte",
    [LocationType.TOWN]: "Bereich",
    [`${LocationType.TOWN}_plural`]: "Bereiche",
    [LocationType.DISTRICT]: "Standort",
    [`${LocationType.DISTRICT}_plural`]: "Standorte",
    [LocationType.LOCATION]: "Fahrer Standort",
    [`${LocationType.LOCATION}_plural`]: "Fahrer Standorte",
  },
  collection_point_administration: {
    data: {
      town: "Bereich - Bereichsnr.",
    },
  },
  user_role: {
    TOWN_ADMIN: "Bereichsleiter",
    ASSOCIATION: "Industriekunde",
  },
  navigation: {
    disposal_merchant_overview: "Abladestelle",
  },
}
