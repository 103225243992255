/*global google*/
import React, { FunctionComponent, useEffect, useMemo, useState } from "react"
import { InfoWindow, Marker, useGoogleMap } from "@react-google-maps/api"
import lodash from "lodash"
import { CustomMarkerTooltip } from "./custom-marker-tooltip"
import { StopStatus } from "../../../../api/graphql/graphql-global-types"
import { useTheme } from "@mui/material"

// marker colors
const COLLECTION_POINT_ERROR = "ff0000"
const COLLECTION_POINT_BELOW_40 = "1aad03"
const COLLECTION_POINT_BELOW_80 = "e6e600"
const COLLECTION_POINT_ABOVE_80 = "b30000"
const COLLECTION_POINT_ON_DEMAND = "a8a3a3"
const TOUR_UNKNOWN_TYPE = "4b963e"
const TOUR_FINISHED_COLLECTION_POINT = "58af47"
const TOUR_PENDING_COLLECTION_POINT = "e86161"
const TOUR_DEPARTURE_POINT = "0f6100"
const TOUR_DISPOSAL_MERCHANT = "0197f6"

interface ICustomMarkerProps {
  id: number
  lat: number
  lng: number
  filllevel?: number
  onDemand?: boolean
  onClick?: (id: number) => void
  isSelected: boolean
  number?: string
  type?: string
  status?: string
  collection_point_id?: number
  disableInfoWindow?: boolean
  disableZoom?: boolean
  doRenderCustomInfoWindow?: boolean
  renderCustomInfoWindow?: (markerId: number) => JSX.Element | ""
  referenceDate?: Date
  colorOverride?: string
  disableMultipleInfoWindows?: boolean
  currentOpenInfoWindowId?: number
  setCurrentOpenInfoWindowId?: (id: number) => void
}

export const CustomMarker: FunctionComponent<ICustomMarkerProps> = (props) => {
  const {
    id,
    lat,
    lng,
    disableInfoWindow,
    disableZoom,
    doRenderCustomInfoWindow,
    renderCustomInfoWindow = () => {
      return ""
    },
    onDemand,
    referenceDate,
    onClick,
    disableMultipleInfoWindows,
    currentOpenInfoWindowId,
    setCurrentOpenInfoWindowId,
  } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const instance = useGoogleMap()
  const theme = useTheme()

  useEffect(() => {
    if (!props.isSelected) {
      setIsOpen(false)
    } else if (instance) {
      if (!disableZoom) {
        instance.setZoom(15)
      }
      if (!isOpen) {
        onMarkerClicked()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSelected, instance])

  useEffect(() => {
    if (!disableMultipleInfoWindows || !currentOpenInfoWindowId) return
    if (currentOpenInfoWindowId !== id) {
      setIsOpen(false)
    }
  }, [currentOpenInfoWindowId, disableMultipleInfoWindows, id])

  const collectionPointId = props.type === "collection_point" ? props.collection_point_id : Number(props.id)

  const onMarkerClicked = () => {
    if (!instance) return
    if (!disableZoom) {
      ;(instance as any).zoom = 15
    }

    const hasCorrectType = !props.type || props.type === "collection_point"
    if (hasCorrectType && !disableInfoWindow) {
      setIsOpen(true)
      setCurrentOpenInfoWindowId?.(id)
    } else {
      instance.setCenter(new google.maps.LatLng(lat, lng))
    }

    onClick?.(id)
  }

  const iconColor = useMemo(() => {
    if (props.colorOverride) {
      return props.colorOverride
    }

    if (!props.type) {
      let color = theme.palette.primary.main.replace("#", "")

      if (!lodash.isNil(props.filllevel)) {
        switch (true) {
          case props.filllevel === -1:
            color = COLLECTION_POINT_ERROR

            break
          case props.filllevel <= 40:
            color = COLLECTION_POINT_BELOW_40
            break
          case props.filllevel < 80:
            color = COLLECTION_POINT_BELOW_80
            break
          default:
            color = COLLECTION_POINT_ABOVE_80
        }
      }

      if (onDemand) {
        color = COLLECTION_POINT_ON_DEMAND
      }

      return color
    }

    let color: string = TOUR_UNKNOWN_TYPE

    if (props.type === "collection_point") {
      color = props.status === StopStatus.FINISHED ? TOUR_FINISHED_COLLECTION_POINT : TOUR_PENDING_COLLECTION_POINT
    } else if (props.type === "departure_point") {
      color = TOUR_DEPARTURE_POINT
    } else if (props.type === "disposal_merchant") {
      color = TOUR_DISPOSAL_MERCHANT
    }

    return color
  }, [props.type, props.filllevel, props.status, onDemand, theme, props.colorOverride])

  const iconText = useMemo(() => {
    if (!props.type) {
      let text = "•"
      if (!lodash.isNil(props.filllevel)) {
        text = props.filllevel.toString()
        switch (true) {
          case props.filllevel === -1:
            text = "-"
            break
        }
      }

      return text
    }

    return props.number ? props.number : "•"
  }, [props.type, props.filllevel, props.number])

  const Icon = useMemo(() => {
    const svg = `
          <svg width="24" height="44" viewBox="0 0 24 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 42C10 22 2 20 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 20 14 22 12 42Z"  fill="#${iconColor}" stroke="black" stroke-width="2"/>
          <text text-anchor="middle" font-weight="bold" font-size="0.7rem" fill="black" font-family="Arial" >
              <tspan dominant-baseline="middle" x="12" y="12">
                  ${iconText}
              </tspan>
          </text>
      </svg>
    `

    return {
      url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg),
    }
  }, [iconColor, iconText])

  const getInfoWindowContent = () => {
    if (doRenderCustomInfoWindow) {
      return renderCustomInfoWindow(id)
    } else {
      return <CustomMarkerTooltip collectionPointId={collectionPointId} referenceDate={referenceDate} isOpen={isOpen} />
    }
  }

  const infoWindowPixelOffset = new google.maps.Size(0, -30.75)
  return (
    <Marker
      key={`map_${id}`}
      position={{
        lat: lat,
        lng: lng,
      }}
      icon={Icon}
      onClick={onMarkerClicked}
    >
      {isOpen && (
        <InfoWindow
          onCloseClick={() => setIsOpen(false)}
          options={{
            pixelOffset: infoWindowPixelOffset,
          }}
          position={{
            lat,
            lng,
          }}
        >
          {getInfoWindowContent()}
        </InfoWindow>
      )}
    </Marker>
  )
}
